import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastClose } from "@radix-ui/react-toast";
import "./faToastClose.scss";

type FaToastCloseProps = {
    children?: React.ReactNode;
};

export function FaToastClose(props: FaToastCloseProps) {
    return (
        <>
            <ToastClose asChild className="fa-toast-close--sm">
                <button>
                    <FontAwesomeIcon icon={faXmark} />
                </button>
            </ToastClose>
            <ToastClose asChild className="fa-toast-close--md">
                <button>{props.children || "Schließen"}</button>
            </ToastClose>
        </>
    );
}
