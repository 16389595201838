import { ToastProvider } from "@radix-ui/react-toast";
import { FaToastAction } from "./FaToastAction/FaToastAction";
import { FaToastClose } from "./FaToastClose/FaToastClose";
import { FaToastRoot } from "./FaToastRoot/FaToastRoot";
import { FaToastViewport } from "./FaToastViewport/FaToastViewport";

export const Provider = ToastProvider;
export const Viewport = FaToastViewport;
export const Root = FaToastRoot;
export const Close = FaToastClose;
export const Action = FaToastAction;
