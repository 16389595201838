import * as Toast from "@radix-ui/react-toast";
import clsx from "clsx";
import { useState } from "react";
import { FaToastClose } from "@/components/FaToast/FaToastClose/FaToastClose";
import { ToastColor } from "@/components/ToastContext";
import "./faToastRoot.scss";

type FaToastRootProps = {
    message: ToastMessage | undefined;
    onDismiss: () => void;

    /**
     * Duration in milliseconds the toast is visible
     * @defauilt 5_000
     */
    duration?: number;

    children?: React.ReactNode;
};

export function FaToastRoot(props: FaToastRootProps) {
    // we store the current (latest) message in state.
    // this is important as it allows us to keep showing
    // the toast while it may perfom exit-animations
    const [message, setMessage] = useState(props.message);
    if (props.message && props.message.id !== message?.id) {
        setMessage(props.message);
    }

    return (
        <Toast.Root
            key={message?.id}
            duration={props.duration || 5_000}
            defaultOpen={!!message}
            onOpenChange={open => {
                if (!open) {
                    props.onDismiss();
                }
            }}
            className={clsx("fa-toast-root", {
                "fa-toast-root--color-danger": message?.color === "danger",
                "fa-toast-root--color-warning": message?.color === "warning",
                "fa-toast-root--color-success": message?.color === "success",
                "fa-toast-root--color-primary": message?.color === "primary",
            })}>
            <Toast.Description className="fa-toast-root__message">{message?.message}</Toast.Description>

            <div className="fa-toast-root__actions">{props.children || <FaToastClose />}</div>
        </Toast.Root>
    );
}

export type ToastMessage = {
    id: string;
    message: string;
    color: ToastColor;
};
